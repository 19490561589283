<template>
  <a-modal
    :maskClosable="false"
    title="申请发票"
    style="top: 8px"
    width="80%"
    v-model="visible"
    @ok="onSubmit"
    @cancel="toCancel"
  >
    <a-tabs default-active-key="1" size="small" type="card">
      <a-tab-pane key="1" tab="基础信息" force-render>
        <a-divider orientation="left">发票信息</a-divider>
        <a-descriptions :column="2" size="small">
          <a-descriptions-item label="发票类型">
            <a-select
              default-value="请选择"
              style="width: 120px"
              @change="InvoiceTypeHandleChange"
              v-decorator="['select', { rules: [{ required: true, message: '请选择发票类型!' }] }]"
            >
              <a-select-option :value="item.id" v-for="(item, index) in InvoiceType" :key="index">
                {{ item.title }}
              </a-select-option>
            </a-select>
          </a-descriptions-item>
          <a-descriptions-item label="抬头类型">{{ info.receivingType == 1 ? '个人' : '公司' }}</a-descriptions-item>
          <a-descriptions-item :label="info.receivingType == 1 ? '发票抬头' : '公司名称'"
            >{{ info.invoiceTitle }}
            <a style="padding-left: 15px" @click="chooseInvoiceTitle">选择抬头</a></a-descriptions-item
          >
          <a-descriptions-item label="纳税人识别码">{{ info.taxpayerNumber }}</a-descriptions-item>
          <a-descriptions-item label="注册电话">{{ info.regPhone }}</a-descriptions-item>
          <a-descriptions-item label="注册地址">{{ info.regAddress }}</a-descriptions-item>
          <a-descriptions-item label="开户银行">{{ info.depositBank }}</a-descriptions-item>
          <a-descriptions-item label="银行账号">{{ info.bankAccount }}</a-descriptions-item>
          <a-descriptions-item label="开票内容">
            <a-select default-value="请选择" style="width: 120px" @change="invoiceContentTypeHandleChange">
              <a-select-option value="1"> 商品明细 </a-select-option>
              <a-select-option value="2"> 商品类别 </a-select-option>
            </a-select>
          </a-descriptions-item>
          <a-descriptions-item label="税率">
            <a-select default-value="请选择" style="width: 120px" @change="taxRateIdHandleChange">
              <a-select-option :value="item.id" v-for="(item, index) in InvoiceTaxRate" :key="index">
                {{ item.title }}
              </a-select-option>
            </a-select>
          </a-descriptions-item>
        </a-descriptions>
        <a-divider orientation="left">发票备注</a-divider>
        <a-textarea
          v-model="info.remark"
          placeholder="该备注信息会展示在发票上，如有需要请录入，否则无需录入任何信息"
          row="4"
        />
        <a-divider orientation="left">邮寄地址</a-divider>
        <a-descriptions :column="2" size="small">
         <a-descriptions-item label="收货人">
           <p> {{ info.consignee }} {{ info.consigneePhone }}</p>
           
          </a-descriptions-item>
          <a-descriptions-item label="选择地址">
           
          <a @click="onCheckAddress" >重新选择地址</a>
          </a-descriptions-item>
          <a-descriptions-item label="收货地址">
           
           <p>{{ info.province }}{{ info.city }}{{ info.area }}{{ info.address }}</p>
          </a-descriptions-item>
        </a-descriptions>
         <!-- <a-divider orientation="left">订单附件</a-divider>
          <a-row>
            <a-col :md="12">
              <a-form-model-item label="上传附件">
                <QiniuUpload
                  :disabled="handle === 'edit' || handle === 'add'  ? false : true"
                  type="image"
                  :value.sync="imageUrl"
                  :size="5"
                ></QiniuUpload>
              </a-form-model-item>
            </a-col>
          </a-row> -->
        <!-- <a-descriptions :column="1" size="small">
          <a-descriptions-item >
            <a style="padding-left: 15px" @click="chooseInvoiceAddress">选择收货地址</a>
          </a-descriptions-item>
          <a-descriptions-item label="收货人">
            <a-input placeholder="收货人" v-model="info.consignee" />
          </a-descriptions-item>
          <a-descriptions-item label="收货人手机号码">
            <a-input placeholder="收货人手机号码" v-model="info.consigneePhone" />
          </a-descriptions-item>
          <a-descriptions-item label="所属区域">
            <PCD
              placeholder="请选择区域"
              :province.sync="info.province"
              :city.sync="info.city"
              :district.sync="info.area"
              :provinceId.sync="info.provinceId"
              :cityId.sync="info.cityId"
              :districtId.sync="info.areaId"
              ref="pcd"
            >
            </PCD>
          </a-descriptions-item>
          <a-descriptions-item label="详细地址">
            <a-input placeholder="详细地址" v-model="info.address" />
          </a-descriptions-item>
        </a-descriptions> -->
      </a-tab-pane>
      <a-tab-pane key="2" tab="订单详情" force-render>
        <div v-if="details">
          <a-table :data-source="details" bordered rowKey="shipId" :pagination="false">
            <a-table-column title="发货单号" data-index="shipCode" :width="100" :ellipsis="true" align="left" />
            <a-table-column title="实际金额" data-index="payPrice" :width="100" :ellipsis="true" align="left" />
            <a-table-column title="可开票金额" data-index="invoicePrice" :width="100" :ellipsis="true" align="left" />
            <a-table-column title="商品总数" data-index="goodsCount" :width="100" :ellipsis="true" align="left" />
          </a-table>
        </div>
      </a-tab-pane>
    </a-tabs>

    <!-- 选择抬头 -->
    <a-modal
      title="更换抬头"
      style="top: 18px"
      :width="1000"
      :visible="chooseTitleVisible"
      :confirm-loading="chooseTitleConfirmLoading"
      @ok="chooseTitleHandleOk"
      @cancel="chooseTitleHandleCancel"
    >
      <p>
        <a-table
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: chooseTitleOnChange,
            type: 'radio',
            getCheckboxProps: (record) => ({
              props: {
                defaultChecked: record.id == info.dealerInvoiceId,
              },
            }),
          }"
          :columns="columns"
          :data-source="chooseTitleData"
          rowKey="id"
        />
      </p>
    </a-modal>

    <!-- 选择地址 -->
    <a-modal
      title="更换地址"
      style="top: 18px"
      :width="1000"
      :visible="chooseAddressVisible"
      :confirm-loading="chooseAddressConfirmLoading"
      @ok="chooseAddressHandleOk"
      @cancel="chooseAddressHandleCancel"
    >
      <p>
        <a-table
          :row-selection="{
            selectedRowKeys: selectedRowKeysAddress,
            onChange: chooseAddressOnChange,
            type: 'radio',
            getCheckboxProps: (record) => ({
              props: {
                defaultChecked: record.id == info.dealerInvoiceId,
              },
            }),
          }"
          :columns="columnsAddress"
          :data-source="chooseAddressData"
          rowKey="id"
        />
      </p>
    </a-modal>
    <CheckAddressModal ref="CheckAddressModal" @refresh="getEcho"></CheckAddressModal>
  </a-modal>
</template>

<script>
import CheckAddressModal from "@/views/order_info/components/CheckAddressModal.vue"
const qs = require('qs')
const columns = [
  {
    title: '发票抬头',
    dataIndex: 'invoiceTitle',
  },
  {
    title: '抬头类型',
    dataIndex: 'receivingType',
    key: 'receivingType',
    customRender: function (text) {
      if (text == 1) {
        return '个人'
      } else {
        return '公司'
      }
    },
  },
  {
    title: '纳税人识别号',
    dataIndex: 'taxpayerNumber',
  },
  {
    title: '开户银行名称',
    dataIndex: 'depositBank',
  },
  {
    title: '基本开户账号',
    dataIndex: 'bankAccount',
  },
  {
    title: '注册场所地址',
    dataIndex: 'regAddress',
  },
  {
    title: '注册电话',
    dataIndex: 'regPhone',
  },
]
const columnsAddress = [
  {
    title: '收货人',
    dataIndex: 'contacts',
  },
  {
    title: '收货人联系电话',
    dataIndex: 'phone',
  },
  {
    title: '省',
    dataIndex: 'province',
  },
  {
    title: '市',
    dataIndex: 'city',
  },
  {
    title: '区',
    dataIndex: 'area',
  },
  {
    title: '详细地址',
    dataIndex: 'address',
  }
]
export default {
  components: {
    CheckAddressModal,
  },
  data() {
    return {
      columnsAddress,
      rowData: {},
      aid:'',
      info: {},
      details:[],
      shipList: [],
      shipInfo: {},
      visible: false,
      logList: [],
      queryParam: {},
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      handle: '',
      InvoiceType: [],
      InvoiceTaxRate: [],
      chooseTitleVisible: false,
      chooseTitleConfirmLoading: false,
      columns,
      chooseTitleData: [],
      selectedRowKeys: [],
      chooseAddressData: [],
      selectedRowKeysAddress: [],
      chooseAddressVisible: false,
      chooseAddressConfirmLoading: false,

    }
  },
  methods: {
    getEcho(row){
      this.info = row
      this.info.consigneePhone = row.phone
      this.info.consignee = row.contacts
    },
    onCheckAddress() {
      // console.log(this.aid)
      this.$refs.CheckAddressModal.show(this.aid)
    },
    //选择地址确认事件
    chooseAddressHandleOk(){
      if (this.selectedRowKeysAddress.length > 0) {
        const chooseId = this.selectedRowKeysAddress[0]
        for (let i = 0; i < this.chooseAddressData.length; i++) {
          if (this.chooseAddressData[i].id == chooseId) {
            this.$set(this.info, 'consignee', this.chooseAddressData[i].contacts)
            this.$set(this.info, 'consigneePhone', this.chooseAddressData[i].phone)
            this.$set(this.info, 'address', this.chooseAddressData[i].address)
            this.$set(this.info, 'province', this.chooseAddressData[i].province)
            this.$set(this.info, 'provinceId', this.chooseAddressData[i].provinceId)
            this.$set(this.info, 'city', this.chooseAddressData[i].city)
            this.$set(this.info, 'cityId', this.chooseAddressData[i].cityId)
            this.$set(this.info, 'area', this.chooseAddressData[i].area)
            this.$set(this.info, 'areaId', this.chooseAddressData[i].areaId)
            this.chooseAddressVisible = false
            this.$nextTick(() => {
              this.$refs.pcd.setPCD()
            })
            return
          }
        }
      } else {
        this.$message.warning('请选择一条数据')
      }
    },
    //选择地址
    chooseAddressOnChange(selectedRowKeys){
      console.log('chooseAddressOnChange changed: ', selectedRowKeys)
      this.selectedRowKeysAddress = selectedRowKeys
    },
    //选择标题
    chooseTitleOnChange(selectedRowKeys) {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      this.selectedRowKeys = selectedRowKeys
    },
    //选择抬头取消事件
    chooseTitleHandleCancel() {
      this.chooseTitleVisible = false
    },
    //选择地址取消事件
    chooseAddressHandleCancel(){
      this.chooseAddressVisible = false
    },
    //选择抬头确定事件
    chooseTitleHandleOk() {
      if (this.selectedRowKeys.length > 0) {
        const chooseId = this.selectedRowKeys[0]
        for (let i = 0; i < this.chooseTitleData.length; i++) {
          if (this.chooseTitleData[i].id == chooseId) {
            this.$set(this.info, 'invoiceTitle', this.chooseTitleData[i].invoiceTitle)
            this.$set(this.info, 'receivingType', this.chooseTitleData[i].receivingType)
            this.$set(this.info, 'taxpayerNumber', this.chooseTitleData[i].taxpayerNumber)
            this.$set(this.info, 'depositBank', this.chooseTitleData[i].depositBank)
            this.$set(this.info, 'bankAccount', this.chooseTitleData[i].bankAccount)
            this.$set(this.info, 'regAddress', this.chooseTitleData[i].regAddress)
            this.$set(this.info, 'regPhone', this.chooseTitleData[i].regPhone)
            this.chooseTitleVisible = false
            return
          }
        }
      } else {
        this.$message.warning('请选择一条数据')
      }
    },
    //选择发票抬头
    chooseInvoiceTitle() {
      this.chooseTitleVisible = true
    },
    //选择收货地址
    chooseInvoiceAddress(){
      this.chooseAddressVisible = true
    },
    InvoiceTypeHandleChange(value) {
      console.log(`InvoiceTypeHandleChange ${value}`)
      this.info.invoiceTypeId = value
    },
    invoiceContentTypeHandleChange(value) {
      console.log(`invoiceContentTypeHandleChange ${value}`)
      this.info.invoiceContentType = value
    },
    taxRateIdHandleChange(value) {
      console.log(`taxRateIdHandleChange ${value}`)
      this.info.taxRateId = value
    },
    toCancel() {
      this.visible = false
    },
    onSubmit() {
      // console.log(this.info)
      this.info.details = this.details
      this.axios.post('/api/order/invoice/orderShipInfo/addData', this.info).then((res) => {
        if (res.code == 200) {
          this.$notification.success({ message: res.message })
          this.visible = false
          this.$emit('change')
        }
      })
    },
    showInfo(shipIdList, dealerId) {
      this.aid = dealerId
      this.visible = true;
      this.handle = name;
      let tempData={shipIdList:shipIdList,dealerId:dealerId};
      this.axios
        .post(`/api/order/invoice/orderShipInfo/getGoodsDetailById`,tempData)
        .then((res) => {
          if (res.code == 200) {
            let vo = res.body.details.map(e=>{
              return{
                invoiceOwnership:e.orderBelongsId,
                ...e
              }
            })
            this.details = vo;
            this.info = res.body
            this.$nextTick(() => {
              // this.$refs.pcd.setPCD()
              this.getTitleAndAddress(dealerId);
            })
          }
        })
        .catch((err) => {})
    },
    getTitleAndAddress(dealerId){
      //发票
      this.axios.get(`/api/dealer/dealer/dealerInfo/selectChooseInvoiceInfoById/`+dealerId).then((res) => {
        if (res.code == 200) {
          this.chooseTitleData = res.body
        }
      })

      //地址
      this.axios.get(`/api/dealer/dealer/dealerInfo/getDealerIdChooseAddressInfo/`+dealerId).then((res) => {
        if (res.code == 200) {
          this.chooseAddressData = res.body
        }
      })
    }
  },
  created() {
    this.axios.get(`/api/base/system/dictionary/selectByCode/发票类型`).then((res) => {
      if (res.code == 200) {
        this.InvoiceType = res.body
      }
    })
    this.axios.get(`/api/base/system/dictionary/selectByCode/发票税率`).then((res) => {
      if (res.code == 200) {
        this.InvoiceTaxRate = res.body
      }
    })

  },
}
</script>

<style lang="less" scoped>
.shipments-bts {
  text-align: right;
  // margin-top: 20px;
}
</style>